import {Title} from '@angular/platform-browser';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AgaPageTitleStrategy extends TitleStrategy {
	constructor(private readonly title: Title, private translateService: TranslateService) {
		super();
	}

	override updateTitle(routerState: RouterStateSnapshot): void {
		const title = this.buildTitle(routerState);
		if (title !== undefined) {
			this.title.setTitle(title.includes('.') ? `${this.translateService.instant(title)}` : title);
		} else {
			this.title.setTitle(this.translateService.instant('pageTitle.generic'));
		}
	}

	/** To dynamically set a page title at the component level*/
	setPageTitle(title: string): void {
		this.buildTitle = (routerState: RouterStateSnapshot) => title;
		this.updateTitle(null);
		this.buildTitle = super.buildTitle;
	}
}
